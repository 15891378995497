/***
 * 请求的接口的方法
 */
//请求的axios
import axios from 'axios'
import Qs from 'qs'
//接口地址
import base from './base'
//引入md5 
import md5 from 'md5-js'

//请求方法
const api = {
    /**
     * H5页面 用户名，电话号码绑定
     */
    postCovInfo(params) {
        // console.log("params")
        // console.log(params)
        console.log('此处在三星手机浏览器下有时会又问题，下面的axios有时不会被触发！')
        // alert('此处在三星手机浏览器下有时会又问题，下面的axios有时不会被触发！')
        return axios.post(base.ztbHost + base.ztbBindPhone, params)
    },
    /**
     * H5页面价格挡位合成
     */
    postUserPhoneAndPrice(params) {
        // console.log("params")
        // console.log(params)
        return axios.post(base.ztbHost + base.postUserPhoneAndPrice, params)
    },

    postWachPay(params) {
        return axios.post(base.ztbHost + base.h5Pay, params)
    },

    postWachPay2(params) {
        return axios.post(base.ztbHost + base.h5Pay2, params)
    },

    postWachPay3(params) {
        return axios.post(base.ztbHost + base.h5Pay3, params)
    },

    postAlipay(params) {
        const headers = {'Content-Type':'application/x-www-form-urlencoded','Access-Control-Allow-Origin':'*'}
        // return axios.post(base.ztbHost + base.AliPay, params
        return axios.post(base.ztbHost + base.AliPay3, params, headers
            // params:{
            //     'price': 12.34
            // }
            // ,
            // headers:{
            //     'Content-Type':'application/x-www-form-urlencoded',
            //     'Access-Control-Allow-Origin':'*' //cors错误是因为请求头没加Access-Control-Allow-XXX信息
            // }
        )
    },

    postAlipayResult(params) {
        return axios.post(base.ztbHost + base.AliPayResult, params)
    },
    /**
     * 微信支付后更新订单与用户状态
     */
    postWxOrderUpdate(params) {
        return axios.post(base.ztbHost + base.wxInOrderUpdate, params)
    },
}

export default api