<template>
  <div class="into1">
    <!-- <div class="bg001">
        <h1><van-icon name="fire-o" color="#ee0a24" /></h1>
    </div> -->
    <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item><img src="../../assets/images/banner-首页.png" alt="" width="100%" height="100%"/></van-swipe-item>
      <van-swipe-item><img src="../../assets/images/banner-自由练习.png" alt="" width="100%" height="100%"/></van-swipe-item>
    </van-swipe> -->
    <van-swipe style="height: 303px;" :autoplay="3000" indicator-color="white" vertical>
      <van-swipe-item><img src="../../assets/images/banner-首页.png" alt="" width="100%" height="100%"/></van-swipe-item>
      <van-swipe-item><img src="../../assets/images/banner-自由练习.png" alt="" width="100%" height="100%"/></van-swipe-item>
    </van-swipe>
    <!-- <van-tag type="primary">{{jgName}}</van-tag> -->
    <van-divider content-position="left" dashed>归属:{{jgName}}</van-divider>

    <div class="bg002">
      <ul>
        <li v-if="checkPlatForm == 0 || checkPlatForm == 2"><a class="button" @click="clkWach">微信支付</a></li>
        <li v-if="checkPlatForm == 1 || checkPlatForm == 2"><a class="button" @click="clkZfb">支付宝支付</a></li>
        <!-- <li><a class="button" @click="visible = true">其他支付</a></li> -->
      </ul>
      <!-- <p>- 上海帛钥智能科技有限公司 -</p> -->
      <p><img src="../../assets/logo.png" width="40%" /></p>
    </div>

    <van-dialog
      v-model="isShow"
      show-cancel-button
      :beforeClose="beforeClose"
    >
      <template v-slot:title>
        <h3 style="display: block; padding:0.5rem 0.3rem; color:#909399; word-spacing: 1rem;">请绑定您的个人信息</h3>
      </template>
      <van-field
          v-model="userName"
          label="姓名"
          placeholder="请输入您真实的姓名"
      />
      <van-field
          v-model="userPhone"
          label="手机号"
          placeholder="请输入手机号"
          type="number"
          maxlength="11"
      />
      <template>
        <span style="display: block; padding:0.5rem 0.3rem; color:#909399; word-spacing: 1rem;">支付成功后，您的授权信息将由短信发送给您，请注意查收</span>
      </template>
      <!-- <template>
        <span style="display: block; padding:0.3rem 0.3rem; color:yellowgreen; word-spacing: 1rem; text-align: center;" @click="sqfk">--微信直接授权--</span>
      </template> -->
    </van-dialog>

    <!-- <Cat-dialog width="25%" top="100px" :visible.sync="visible">
      <template v-slot:title>
        <h3>请输入您的个人信息</h3>
      </template>
      <template>
        <p>主体内容，随便写点什么...</p>
        <input type="text" placeholder="请输入信息" />
      </template>
      <template v-slot:footer>
        <Cat-button @click="visible = false">取消</Cat-button>
        <Cat-button type="primary" @click="visible = false">确定</Cat-button>
      </template>
    </Cat-dialog> -->

  </div>
  
</template>

<script>
import api from "../../api/ztb";
import { Dialog } from 'vant';

  // Dialog({ message: '提示' });
export default {
  // name: "帛钥智能 - 渠道支付",
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },

  data() {
    return {
      high: ['rrt','uui'], //高风险区数据
      mid: [], //低风险区域
      dialogVisible: false,
      isShow: false,
      userName: '',
      userPhone: '',
      visible: false,
      jgName: "",
      checkPlatForm: 2, //媒介检测，0微信，1，支付宝，2浏览器
      orderId: "1dsf2343234324324"
    };
  },

  // computed: {

  // },

  created() {
    this.jgNames()
    this.IsWeixinOrAlipay()
  },


  methods: {
    IsWeixinOrAlipay(){
      var ua = window.navigator.userAgent.toLowerCase();
      //判断是不是微信
      if ( ua.match(/MicroMessenger/i) == 'micromessenger' ){
        console.log("********************** WeiXIN模式")
        this.checkPlatForm = 0;
        return "WeiXIN";
      }
      //判断是不是支付宝
      if (ua.match(/AlipayClient/i) == 'alipayclient') {
        console.log("********************** Alipay模式")
        this.checkPlatForm = 1;
        return "Alipay";
      }
      //哪个都不是
      console.log("********************** 普通浏览器打开模式")
      this.checkPlatForm = 2;
      return "false";
    },

    jgNames(){
      // http://localhost:8081/#/into1?jg=0002
      if (this.$route.query.jg == '0001'){
        return this.jgName = "职通宝机构"
      }else if(this.$route.query.jg == '0002'){
        return this.jgName = "恒山机构"
      }else if(this.$route.query.jg == '0003'){
        return this.jgName = "华山机构"
      }else if(this.$route.query.jg == '0004'){
        return this.jgName = "嵩山机构"
      }else{
        return this.jgName = "其他机构..."
      }
    },

    clkZfb(){
      // Dialog.alert({
      //   message: '支付宝渠道暂未开通，请选择微信渠道付款'
      // }).then(() => {
      //   // on close
      // })

      //支付宝支付
      this.$router.push({path: 'AliPay', query: {orderId: this.orderId}});
    },

    clkWach(){
      this.isShow = !this.isShow
    },
    // beforeClose(action, don){
    //   console.log(action)
    //   if(action == confirm){
    //     console.log("用户信息绑定成功！！！")
    //   }
    //   if(action == cancel){
    //     console.log("取消绑定操作 ！！！")
    //   }
    // }

    beforeClose(action, done) {
      if(action === 'confirm') {
          console.log("action = ", action)
          if(!this.userName || !this.userPhone) {
            this.$toast("请输入姓名及电话号码")
            done(false) //不关闭弹框
          }else{
            var data = new URLSearchParams() ;
            data.append('username', this.userName);   // 添加数据
            data.append('userphone', this.userPhone);   // 添加数据this.$route.query.jg
            data.append('institution_code', this.$route.query.jg);
            this.submitFormUser(data)
            setTimeout(done, 1000)
          }

      } else if(action === 'cancel') {
          // console.log("action = ", action)
          // this.$toast("取消绑定！")
          done(true) //关闭
      }
    },

    //发送绑定
    submitFormUser(params){
      api.postCovInfo(params).then((res) => {
        console.log(res);
        if(res.status == 200){
          this.$toast("绑定成功！")
          console.log("绑定成功！");
          console.log(res.data);
          this.sqfk()
        }else{
          console.log("绑定失败！");
        }
      });
    },

    sqfk(){
      console.log('请求微信授权！！！！')
      setTimeout(
        top.location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8120e608eacdd78f&redirect_uri=http://235f6cfb.cpolar.cn&response_type=code&scope=snsapi_userinfo&state=type%3Dquan%2Curl%3Dhttp%3A%2F%2Fmm.dianping.com%2Fweixin%2Faccount%2Fhome',
        5000
      )
    },

    // 判断是否中文
    isChinese(temp){
      var re=/[^\u4E00-\u9FA5]/;
      if (re.test(temp)) return false ;
      return true ;
    },

  }
};
</script>

<style lang='less' scoped>
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: auto;
    overflow: hidden;
    text-align: center;
    // background-color: #39a9ed;
  }

.into1 {
  background: #fff;
}

.bg {
  height: 3.6rem;
  background: url("../../assets/images/area-1.png") no-repeat;
  background-size: cover;
  padding-top: 5rem;
}

.bg001 {
  height: 5rem;
  background: url("../../assets/images/area-1.png") no-repeat;
  background-size: cover;
  padding: 0.25rem 0.25rem;
  color: #D43030;
}

.bg002 {
  background:#fff;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  // padding: 0.2rem;
  border-radius: 0.2rem;
  min-height: 8rem;
  margin-bottom: 0.2rem;
  p {
    margin-top: 1rem;
    text-align: center;
    color:#777;
  }
}

.bg002 ul li{
  margin:0.8rem 0.15rem;
  text-align: center;
}

.bg002 .button {
    background-color: #4D88F2; /* Green */
    border-radius: 0.15rem;
    border: none;
    color: white;
    padding: 0.15rem 0.32rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.25rem;
    width: 45vw;
    height: 10vw;
    line-height: 10vw;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}
.bg002 .button:hover {
    background-color: #4e8ebf; /* Green */
    border-radius: 0.15rem;
    border: none;
    color: white;
    padding: 0.15rem 0.32rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 45vw;
    height: 10vw;
    line-height: 10vw;
}

.content {
  background: #ECD773;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  padding: 0.2rem;
  border-radius: 0.2rem;
  min-height: 10rem;
  margin-bottom: 0.2rem;
  .th {
    margin-bottom: 0.3rem;
    font-size: 0.34rem;
  }

  .th:before {
    display: inline-block;
    width: 0.1rem;
    height: 0.3rem;
    margin-right: 0.1rem;
    background-color: #4169e2;
    content: "";
    vertical-align: middle;
  }

  .title{
      font-size: 0.3rem;
      margin-bottom: 0.3rem;
      img{
          width: 0.4rem;
          vertical-align: top;
      }
  }

  .list{
      li{
          margin-bottom: 0.2rem;
          color: #333;
          background:#f7f7f7;
          padding:0.1rem;
          border-radius: 0.1rem;
          letter-spacing: 0.01rem;
          line-height: 0.5rem;
      }
  }
}
</style>